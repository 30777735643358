import { db } from "firebase.js";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useContext, createContext } from "react";
import { supabase } from "supabaseClient";



const SupabaseContext = createContext();

export function useSupabase() {
    return useContext(SupabaseContext);
}

export function SupabaseProvider({children}) {

    const getPools = async () => {
        const { data, error } = await supabase
            .from('pools')
            .select('*')
        if (error) {
            console.log(error);
            throw error
        }
        console.log(data);
        return data
    }

    const getInstructors = async () => {
        const { data, error } = await supabase
            .from('instructors')
            .select('*')
        if (error) {
            throw error
        }
        return data
    }

    const addLessonToDb = async (lesson, user) => {
        console.log(lesson);
        const { data, error } = await supabase
            .from('bookings')
            .insert({
                user_id: user.uid,
                pool_id: lesson.pool_id,
                instructor_id: lesson.instructor_id,
                booking_time: lesson.booking_time,
                status: 'pending',
                address: lesson.address,
            })
        if (error) {
            throw error
        }
        return data
    }

    const removeLessonFromDb = async (lesson, user) => {
        const { data, error } = await supabase
            .from('bookings')
            .delete()
            .eq('id', lesson.id)
            .eq('user_id', user.uid)
        if (error) {
            throw error
        }
        return data
    }

    const getUserPendingBookings = async (user) => {
        const { data, error } = await supabase
            .from('bookings')
            .select('*')
            .eq('user_id', user.uid)
            .eq('status', 'pending')
        if (error) {
            throw error
        }
        return data
    }

    const bookLessons = async (lessons, user) => {
        // Update lessons to go from pending to booked
        const { data, error } = await supabase
            .from('bookings')
            .update({ status: 'booked' })
            .in('id', lessons.map((lesson) => lesson.id))
            .eq('user_id', user.uid)
        if (error) {
            throw error
        }
        return data
    }

    const getBookedLessons = async (user) => {
        const { data, error } = await supabase
            .from('bookings')
            .select('*')
            .eq('user_id', user)
            .eq('status', 'booked')
            .select(
                `
                *,
                pool:pool_id(*),
                instructor:instructor_id(*)
                `
            )
        if (error) {
            throw error
        }
        return data
    }

    const cancelLesson = async (lesson, user) => {
        const { data, error } = await supabase
            .from('bookings')
            .update({ status: 'cancelled' })
            .eq('id', lesson.id)
            .eq('user_id', user.uid);
        if (error) {
            throw error
        }

        const userRef = doc(db, "users", user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
            updateDoc(userRef, {
                ClassesAvailable: userSnap.data().ClassesAvailable + 1,
            });
        }

        return data
    }

    const getSessionScheduleAvailability = async (session) => {
        const { data, error } = await supabase
            .from('session_schedule')
            .select('*')
            .eq('session_id', session)
            .select(
                `
                *,
                lesson_type:lesson_type(*),
                skill_range:skill_range(*)
                `
            )
            .order('time', { ascending: true })
        if (error) {
            throw error
        }

        // Map through each lesson and see if it exceeds the max participant count in the enrollments table
        const availability = [];
        for (const lesson of data) {
            const { count, error } = await supabase
                .from('session_enrollments')
                .select('*', { count: 'exact' })
                .eq('session_id', lesson.id)
            if (count <= lesson.lesson_type.max_participants) {
                availability.push({
                    ...lesson,
                    available: true,
                    enrollments: count,
                    currentParticipants: [],
                });
            }
            if (error) {
                throw error
            }
        }

        return availability
    }

    const addSwimmerToGroupLesson = async (lessons) => {

        // Check if the swimmer is already enrolled in the lesson, if not then add them
        for (const lesson of lessons) {
            const { count, error } = await supabase
                .from('session_enrollments')
                .select('*', { count: 'exact' })
                .eq('session_id', lesson.id)
                .eq('swimmer_id', lesson.swimmer.id)
            if (count === 0) {
                const { data, error } = await supabase
                    .from('session_enrollments')
                    .insert({
                        session_id: lesson.id,
                        user_id: lesson.swimmer.user,
                        swimmer_id: lesson.swimmer.id,
                        status: 'pending',
                    })
                if (error) {
                    throw error
                }
            }
        }

        return lessons
    }

    const removeSwimmerFromGroupLesson = async (lesson, swimmer) => {
        const { data, error } = await supabase
            .from('session_enrollments')
            .delete()
            .eq('session_id', lesson.id)
            .eq('swimmer_id', swimmer.id)
        if (error) {
            throw error
        }
        return data
    }

    const enrollSwimmerInGroupLessons = async (lessons) => {
        // Update enrollment from pending to booked for each swimmer
        for (const lesson of lessons) {
            const { data, error } = await supabase
                .from('session_enrollments')
                .update({ status: 'booked' })
                .eq('session_id', lesson.id)
                .eq('swimmer_id', lesson.swimmer.id)
            if (error) {
                throw error
            }
        }
    }

    const addSwimmerToPrivateLesson = async (lesson, user, swimmers) => {
        // add a new enrollment for each swimmer
        for (const swimmer of swimmers) {
            const { data, error } = await supabase
                .from('session_enrollments')
                .insert({
                    session_id: lesson.id,
                    user_id: user.uid,
                    swimmer_id: swimmer.id,
                    status: 'pending',
                })
            if (error) {
                throw error
            }
        }

        return swimmers

    }

    const removeSwimmerFromPrivateLesson = async (lesson, user) => {
        const { data, error } = await supabase
            .from('session_enrollments')
            .delete()
            .eq('session_id', lesson.id)
            .eq('user_id', user.uid)
        if (error) {
            throw error
        }
        return data
    }

    const enrollSwimmerInPrivateLesson = async (lesson, user, swimmers) => {
        for(swimmer of swimmers) {
        const { data, error } = await supabase
            .from('session_enrollments')
            .update({ status: 'booked' })
            .eq('session_id', lesson.id)
            .eq('swimmer_id', swimmer.id)
            .eq('user_id', user.uid)
        if (error) {
            throw error
        }
    }
    return swimmers;
    }


    const value = {
        getPools,
        getInstructors,
        addLessonToDb,
        removeLessonFromDb,
        getUserPendingBookings,
        bookLessons,
        getBookedLessons,
        cancelLesson,
        getSessionScheduleAvailability,
        addSwimmerToGroupLesson,
        removeSwimmerFromGroupLesson,
        enrollSwimmerInGroupLessons,
        addSwimmerToPrivateLesson,
        removeSwimmerFromPrivateLesson,
        enrollSwimmerInPrivateLesson
    }

    return (
        <SupabaseContext.Provider value={value}>
            {children}
        </SupabaseContext.Provider>
    )
}