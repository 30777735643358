import { useLocation, useNavigate, useRoutes, useSearchParams } from 'react-router-dom';

// routes
import { useAuth } from 'hooks/AuthContext';
import { useEffect } from 'react';
import { gql, useQuery } from 'urql';
import AuthenticationRoutes from './AuthenticationRoutes';
import MainRoutes from './MainRoutes';
import NewClientRoutes from './NewClientRoutes';
import { useBooking } from 'hooks/BookingContext';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { currentUser } = useAuth();
    const { userData } = useBooking();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const getUserQuery = gql`
        query getUserInfo($id: String!) {
            getUserInfo(id: $id) {
                Email
                FirstName
                LastName
                Address
                City
                PostalCode
                PhoneNumber
            }
        }
    `;

    // Check for referral search params and add them to any redirects
    useEffect(() => {
        if (searchParams.get('referralCode')) {
            localStorage.setItem('referralCode', searchParams.get('referralCode'));
        }
    }, [searchParams]);

    // if no user, redirect route path to login
    useEffect(() => {
        if (!currentUser) {
            // only redirect if not already on login page
            if (!location.pathname.includes('/login') && !location.pathname.includes('/register') && !location.pathname.includes('/forgot-password')) {
                // Include referral search params in redirect
                if (searchParams.get('referralCode')) {
                    navigate('/login?referralCode=' + searchParams.get('referralCode'), { replace: true });
                }
                else {
                    navigate('/login', { replace: true });
                }
            }
        }
    }, [currentUser]);

    // const [results, reexecuteQuery] = useQuery({
    //     query: getUserQuery,
    //     variables: { id: currentUser?.uid },
    //     pause: true
    // });

    // if user is logged in, redirect to onboarding if no user info, run useEffect anytime the route changes
    useEffect(() => {
        if (currentUser) {
            console.log(userData);
            if (!userData) {
                if (!location.pathname.includes('/onboarding')) {
                    if (searchParams.get('referralCode')) {
                        navigate('/onboarding?referralCode=' + searchParams.get('referralCode'), { replace: true });
                    }
                    else {
                        navigate('/onboarding', { replace: true });
                    }
                }
            }
            if (location.pathname.includes('/onboarding') && userData) {
                if (searchParams.get('referralCode')) {
                    navigate('/?referralCode=' + searchParams.get('referralCode'), { replace: true });
                } else {
                    navigate('/', { replace: true });
                }
            }
        }
        // if (results.fetching) {
        //     return;
        // };
        // if (currentUser && (results.data === undefined || results.data === null)) {
        //     if (!location.pathname.includes('/onboarding')) {
        //         navigate('/onboarding', { replace: true });
        //     } else {
        //         navigate('/onboarding', { replace: true });
        //     }
        // } else if (currentUser && (results.data !== undefined || results.data !== null)) {
        //     if (location.pathname.includes('/onboarding')) {
        //         navigate('/', { replace: true });
        //     }
        // } else {
        //     navigate('/', { replace: true });
        // }
    }, [currentUser, userData]);

    // redirect to login if user is not logged in
    return useRoutes(currentUser ? [MainRoutes, NewClientRoutes] : [AuthenticationRoutes]);
}
